// extracted by mini-css-extract-plugin
export var animFlipX = "hero-module--anim-flipX--qU3oW";
export var animLineUp = "hero-module--anim-lineUp--B3kQG";
export var bottom = "hero-module--bottom--+Z4S+";
export var breakWord = "hero-module--break-word--e7jPk";
export var contactLink = "hero-module--contact-link--0adb6";
export var corners = "hero-module--corners--a1fVb";
export var heroBackground = "hero-module--hero-background--CanQE";
export var heroContainer = "hero-module--hero-container--InCRF";
export var heroImage = "hero-module--hero-image--dKUVu";
export var heroText = "hero-module--hero-text--uKOns";
export var left = "hero-module--left--ViQEI";
export var monitorIn = "hero-module--monitorIn--k9Esf";
export var rectangle = "hero-module--rectangle--E3aVs";
export var right = "hero-module--right--PHYNB";
export var slideUp = "hero-module--slideUp--kTgNa";
export var subText = "hero-module--sub-text--PfGn5";
export var top = "hero-module--top--wAfo3";